(function () {
    'use strict';
    angular
        .module('userManagerApp')
        .factory('LanguageNotificationChannel', LanguageNotificationChannel);

    LanguageNotificationChannel.$inject = ['$rootScope'];

    function LanguageNotificationChannel($rootScope) {
        var _LANGUAGE_CHANGED_ = 'cspGatewayApp:LANGUAGE_CHANGED';

        var languageChanged = function () {
            $rootScope.$broadcast(_LANGUAGE_CHANGED_);
        };

        var onLanguageChanged = function ($scope, handler) {
            $scope.$on(_LANGUAGE_CHANGED_, function (event) {
                handler();
            });
        };

        return{
            languageChanged: languageChanged,
            onLanguageChanged: onLanguageChanged
        };
    }
})();
