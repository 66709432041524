(function () {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('Redirect', Redirect);

    Redirect.$inject = ['$window', 'UserManager', '$timeout'];

    function Redirect ($window, UserManager, $timeout) {
        var vm = this;
        var service = {
            redirect: redirect
        };
        fetchAllowedRedirects();
        return service;

        function fetchAllowedRedirects() {
            vm.location = UserManager.get({}, function (location) {
                location.allowedRedirects=location.allowedRedirects.map(function (pattern) {
                    return new RegExp(pattern, "gi")
                });
            }, function again() {
                $timeout(fetchAllowedRedirects(), 3000);
            });
        }

        function redirect(url) {
            if(angular.isDefined(vm.location.allowedRedirects)) {
                if (checkRedirectUrl(url, vm.location.allowedRedirects)) {
                    $window.location.href = url;
                    return true;
                }
            }
            return false;
        }

        //check if the given url matches our configured valid redirect URL regex patterns
        function checkRedirectUrl(url, allowedRedirectRegExps) {
            var elem=document.createElement("a");
            elem.href=url;
            var hostname=elem.hostname;
            for(var i=0;i<allowedRedirectRegExps.length; i++) {
                var regex=allowedRedirectRegExps[i];
                if(regex.test(hostname)) {
                    return true;
                }
            }
            return false;
        }
    }
})();
