(function () {
    'use strict';

    angular
        .module('userManagerApp')
        .directive('matchPassword', matchPassword);

    function matchPassword() {

        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        return directive;


        function linkFunc(scope, element, attrs, ctrl) {
            var firstPassword = '#' + attrs.matchPassword;
            element.add(firstPassword).on('keyup', function () {
                scope.$apply(function () {
                    var matchPassword = element.val() === $(firstPassword).val();
                    ctrl.$setValidity('matchPassword', matchPassword);
                });
            });
        }
    }

})();