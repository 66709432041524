(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES', '$window'];

    function translationStorageProvider($cookies, $log, LANGUAGES, $window) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                var preferredLanguage = getPreferredLanauage();
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to preferred language: ' + preferredLanguage);
                $cookies.putObject(name, preferredLanguage);
            }
            return $cookies.getObject(name);
            

        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }

        function getPreferredLanauage() {
            console.log('setting langauge');
            //use browser language if possible
            var preferredLanguage = $window.navigator.language || $window.navigator.userLanguage;
            if(preferredLanguage && preferredLanguage.length >= 2) {
                preferredLanguage = preferredLanguage.substr(0,2);
            }
            if(LANGUAGES.indexOf(preferredLanguage) === -1) {
                preferredLanguage = 'en';
            }
            return preferredLanguage;
        }
    }
})();
