(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$localStorage', '$sessionStorage', '$q'];

    function AuthServerProvider ($http, $localStorage, $sessionStorage, $q) {
        var service = {
            getToken: getToken,
            login: login,
            storeAuthenticationToken: storeAuthenticationToken,
            logout: logout,
            getTopLevelDomain: getTopLevelDomain
        };

        return service;

        function getToken() {
            return null;
        }

        function login (credentials) {
            var data = {
                username: credentials.username,
                password: credentials.password,
                rememberMe: credentials.rememberMe
            };
            var headers = {
                "Authorization" : "Basic d2ViX2FwcDo="
            };

            return $http({
                url: '/auth/login',
                method: 'post',
                data: data,
                headers: headers
            });
        }

        function storeAuthenticationToken(jwt, rememberMe) {
        }

        function logout () {
            //Call logout api on server - this will delete the OAuth2 cookies and therefore logout the user
            return $http.post('auth/logout');
        }

        function getTopLevelDomain () {
            // Returns the top-level domain cookies shall be set to
            return $http.get('/auth/domain');
        }
    }
})();
