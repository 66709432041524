(function() {
    'use strict';

    var jhiItemCount = {
        template: '<div class="info">' +
            '<span data-translate="userManagement.admin.itemCount"  ' +
                'translate-values="{' +
                'currentMin: \'{{(($ctrl.page - 1) * $ctrl.itemsPerPage) == 0 ? 1 : (($ctrl.page - 1) * $ctrl.itemsPerPage + 1)}}\', ' +
                'currentMax: \'{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}}\', '+
                'max: \'{{$ctrl.queryCount}}\''+
                '}">' +
                'Showing {currentMin} - {currentMax} of {max} items.</span>' +
                '</div>',
        bindings: {
            page: '<',
            queryCount: '<total',
            itemsPerPage: '<'
        }
    };

    angular
        .module('userManagerApp')
        .component('jhiItemCount', jhiItemCount);
})();