(function () {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$scope', '$rootScope', '$state', '$interval', '$timeout', 'Auth', 'Redirect', 'AlertService', 'bowser', 'CookieUtils'];

    function LoginController($scope, $rootScope, $state, $interval, $timeout, Auth, Redirect, AlertService, bowser, CookieUtils) {
        var vm = this;

        vm.isIE10OrOlder = bowser.msie && bowser.version <= 10;
        vm.isBrowserTested = bowser.msie || bowser.msedge || bowser.safari || bowser.chrome || bowser.firefox;
        vm.isCookiesEnabled = CookieUtils.isCookiesEnabled();

        vm.countDownInterval = null;
        vm.tooManyAttemptsCounter = 0;

        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.isSigningIn = false;
        //convert ALLOWED_REDIRECTS into regular expressions

        vm.serverResponseError = null;
        vm.ServerResponseError = {
            TOO_MANY_ATTEMPTS_ERROR : 1,
            UNAUTHORIZED_ERROR : 2,
            AUTHENTICATION_ERROR : 3,
            UNKNOWN_ERROR: 4
        }

        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.serverResponse = null;
            $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            if(vm.isSigningIn) {
                return;
            }

            event.preventDefault();
            vm.isSigningIn = true;
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.isSigningIn = false;
                vm.serverResponseError = null;

                if (typeof $state.params.return !== 'undefined') {
                    if (Redirect.redirect($state.params.return)) return false;
                }

                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'login' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                var unsubscribe = $scope.$on('userLanguageApplied', function () {
                    AlertService.success('login.success');
                });

                $scope.$on('$destroy', function () {
                    unsubscribe();
                    if (vm.countDownInterval) {
                        $interval.cancel(vm.countDownInterval);

                    }
                });

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (response) {
                vm.isSigningIn = false;
                if (response.status === 400) {
                    vm.serverResponseError = vm.ServerResponseError.AUTHENTICATION_ERROR;
                } else if(response.status === 401) {
                    vm.serverResponseError = vm.ServerResponseError.UNAUTHORIZED_ERROR;
                } else if (response.status === 423) {
                    vm.serverResponseError = vm.ServerResponseError.TOO_MANY_ATTEMPTS_ERROR;
                    handleTooManyAttemtps(response);
                } else {
                    vm.serverResponseError = vm.ServerResponseError.UNKNOWN_ERROR
                }
            });
        }

        function handleTooManyAttemtps(response) {
            if(vm.countDownInterval) {
                $interval.cancel(vm.countDownInterval);
                vm.countDownInterval = undefined;
            }
            vm.tooManyAttemptsCounter = response.headers("Lock-Duration-Seconds");
            console.log(vm.tooManyAttemptsCounter);
            if (vm.tooManyAttemptsCounter != null) {
                vm.countDownInterval = $interval(countDown, 1000);
            }
        }

        function countDown() {
            vm.tooManyAttemptsCounter--;
            if(vm.tooManyAttemptsCounter <= 0) {
                vm.serverResponseError = null;
                $interval.cancel(vm.countDownInterval);
                vm.countDownInterval = undefined;
            }
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }

    }
})();
