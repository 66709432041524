(function() {
    'use strict';

    var blockedIpAddressTable = {
        templateUrl: 'app/entities/blocked-ip-address/components/blocked-ip-address-table.component.html',
        controller: BlockedIpAddressTableController,
        controllerAs: 'vm'
    };

    angular
        .module('userManagerApp')
        .component('blockedIpAddressTable', blockedIpAddressTable);

    BlockedIpAddressTableController.$inject = ['BlockedIpAddress', 'AlertService'];

    function BlockedIpAddressTableController(BlockedIpAddress, AlertService) {
        var vm = this;

        vm.unblockIpAddress = unblockIpAddress;
        vm.blockedIpAddresses =  [];

        loadBlockedIpAddresses();


        function loadBlockedIpAddresses() {
            BlockedIpAddress.get().$promise.then(function (blockedIpAddresses) {
                vm.blockedIpAddresses = blockedIpAddresses;
            }).catch(function (error) {
                vm.blockedAddresses = [];
                AlertService.error('blockedIpAddress.error.couldNotLoad');
            });
        }

        function unblockIpAddress(ipAddress) {
            BlockedIpAddress.delete({key: ipAddress}).$promise.then(function () {
                loadBlockedIpAddresses();
                AlertService.success('blockedIpAddress.unblock.success');
            }).catch(function (error) {
                AlertService.success('blockedIpAddress.unblock.error');
            });
        }
    }
})();
