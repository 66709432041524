(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if(isUnexpectedError(response)){
                $rootScope.$emit('userManagerApp.httpError', response);
            }
            return $q.reject(response);
        }

        function isUnexpectedError(response) {
            return ! (isFromLogin(response) || isFromPasswordChange(response));
        }

        function isFromPasswordChange(response) {
            if(response.config != null && response.config.url != null
                && response.config.url.indexOf('uaa/api/account/change_password') === 0) {
                return true;
            }
            return false;
        }

        function isFromLogin(response) {
            // the error-alert component does not subscribe to 'userManagerApp.httpError' event before successful login
            // until this is fixed no automatic alerts will be displayed in login view.
            if ((response.status === 400 || response.status === 401 || response.status === 423)
                && response.data && response.data.path
                && response.data.path.indexOf('/api/account') === 0 ) {
                return true;
            }
        }

    }
})();
