(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('AlertController', AlertController);

    AlertController.$inject = ['$scope', 'Principal'];

    function AlertController ($scope, Principal) {
        var vm = this;
        vm.isAuthenticated = null;

        var unsubscribe = $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $scope.$on('$destroy', function() {
            unsubscribe();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function() {

                vm.isAuthenticated = Principal.isAuthenticated;

            });
        }
    }
})();
