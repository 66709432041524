(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state'];

    function HomeController ($scope, Principal, $state) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = function(){$state.go('login')};
        vm.register = register;
        var unsubscribe = $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $scope.$on('$destroy', function() {
            unsubscribe();
        });

        getAccount();

        //for now, home doesn't show anything interesting, so go to settings instead
        $state.go('settings');

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
