(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['Authority', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'Tenant'];

    function UserManagementDialogController (Authority, $uibModalInstance, entity, User, JhiLanguageService, Tenant) {
        var vm = this;

        vm.authorities = Authority.query({
            page: 0,
            size: 2001 // 2000 appears to be the maximum
        });
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.tenants = Tenant.query();
        vm.generatePassword = generatePassword;
        if(entity.id === null) vm.generatePassword();
        // set English as default language
        if(entity.langKey === null) entity.langKey = 'en';

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function generatePassword() {
            vm.user.password = window.generatePassword(20);
        }
    }
})();
