(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$state', '$scope', 'Redirect', 'AlertService'];

    function PasswordController (Auth, Principal, $state, $scope, Redirect, AlertService) {
        var vm = this;

        vm.changePassword = changePasswordAndGoHome;
        vm.isGoHomeEnabled = isGoHomeEnabled;
        vm.goHome = goHome;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePasswordAndGoHome() {
            changePassword(function() {
                goHome();
            });
        }

        function changePassword (returnHome) {
            if (vm.password !== vm.confirmPassword) {
                AlertService.error('global.messages.error.dontmatch');
            } else {
                return Auth.changePassword(vm.oldPassword, vm.password).then(function () {
                    AlertService.success('password.messages.success');
                    resetModelAndForm();

                    if(returnHome) {
                        returnHome();
                    }
                }).catch(function (response) {
                    if(response.status === 400 && response.data != null && response.data.message === "error.badCredentials") {
                        AlertService.error('password.messages.oldPasswordIncorrect');
                    } else if(response.status === 400 && response.data != null && response.data.message === "error.validation") {
                        AlertService.error('password.messages.invalid');
                    } else if(response.status === 400 && response.data != null && response.data.message === "error.reusedPassword") {
                        AlertService.error('password.messages.reusedPassword');
                    } else {
                        AlertService.error('password.messages.error');
                    }
                });
            }
        }
        
        function resetModelAndForm() {
            vm.password = "";
            vm.confirmPassword = "";
            vm.oldPassword = "";
            $scope.form.$setPristine();
        }
        
        function goHome() {
            if(isGoHomeEnabled()) {
                if(Redirect.redirect($state.params.return)) {
                    return;
                }
            }
        }

        function isGoHomeEnabled() {
            return angular.isDefined($state.params.return);
        }
    }
})();
