(function () {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('BlockedIpAddress', BlockedIpAddress);

    BlockedIpAddress.$inject = ['$resource'];

    /**
     *  Service which allows calls to the endpoints of BlockedIpAddressResource.
     */
    function BlockedIpAddress ($resource) {
        var service = $resource('uaa/api/blocked-ip-addresses/:key', {}, {
            'get': {
                method: 'GET', isArray: true
            },
            'delete':{ method:'DELETE'}
        });
        return service;
    }
})();
