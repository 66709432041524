(function () {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', '$rootScope', '$scope', 'JhiLanguageService', 'LanguageNotificationChannel', 'Account', 'CookieUtils'];

    function NavbarController($state, Auth, Principal, ProfileService, $rootScope, $scope, JhiLanguageService, LanguageNotificationChannel, Account, CookieUtils) {
        var vm = this;

        vm.isCookiesEnabled = CookieUtils.isCookiesEnabled();

        vm.isNavbarOpen = false;
        vm.isLanguageCollapsed = true;
        vm.isAdminCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.account = null;
        vm.userData = null;
        vm.isAdmin = false;
        vm.switchLanguageAndClose = switchLanguageAndClose;
        vm.logoutAndClose = logoutAndClose;

        vm.inProduction = null;
        vm.swaggerEnabled = null;

        vm.login = login;
        vm.logout = logout;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        init();

        function init() {

            ProfileService.getProfileInfo().then(function (response) {
                vm.inProduction = response.inProduction;
                vm.swaggerEnabled = response.swaggerEnabled;
            });

            getCurrentLanguage();

            LanguageNotificationChannel.onLanguageChanged($scope, function (result) {
                getCurrentLanguage();
            });

            var unsubscribe = $scope.$on('authenticationSuccess', function () {
                getAccount();
            });

            $scope.$on('$destroy', function() {
                unsubscribe();
            });

            getAccount();

        }

        function getCurrentLanguage() {
            JhiLanguageService.getCurrent().then(function (result) {
                vm.currentLanguage = result;
            });
        }

        function login() {
            collapseNavbar();
            $state.go('login')
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function collapseNavbar() {
            vm.isNavbarOpen = false;
        }

        vm.closeSubDropdowns = function (open) {
            if (!open) {
                vm.isAdminCollapsed = true;
                vm.isLanguageCollapsed = true;
            }
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                if(account !== null) {
                    vm.account = account;
                    vm.userData = "";
                    if (account.firstName != null) {
                        vm.userData = vm.userData + account.firstName + " ";
                    }
                    if (account.lastName != null) {
                        vm.userData = vm.userData + account.lastName + " ";
                    }
                    if (account.email != null) {
                        if (vm.userData.length == 0) {
                            vm.userData = account.email;
                        } else {
                            vm.userData = vm.userData + '(' + account.email + ')';
                        }
                    }
                    vm.isAuthenticated = Principal.isAuthenticated;
                    Principal.hasAuthority('ROLE_USER').then(function (hasAuthority) {
                        vm.subNavBarEnabled = hasAuthority;
                    });
                    Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                        vm.isAdmin = hasAuthority;
                    });
                    JhiLanguageService.getCurrent().then(function (result) {
                        if (result !== account.langKey) {
                            JhiLanguageService.changeLanguage(account.langKey).then(function () {
                                $rootScope.$broadcast('userLanguageApplied');
                            });
                        } else {
                            $rootScope.$broadcast('userLanguageApplied');
                        }
                    });
                }
            });
        }

        function switchLanguageAndClose(switchTo) {
            JhiLanguageService.getCurrent().then(function (result) {
                if (result !== switchTo) {
                    Account.changeLanguage({}, new String(switchTo)); // angular.toJson only works with type 'object'
                    JhiLanguageService.changeLanguage(switchTo);
                    collapseNavbar();
                }
            });
        }

        function logoutAndClose() {
            collapseNavbar();
            vm.logout();
        }
    }
})();
