(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['JhiLanguageService'];

    function JhiLanguageController (JhiLanguageService) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.languages = null;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function changeLanguage (languageKey) {
            JhiLanguageService.changeLanguage(languageKey);
        }
    }
})();
