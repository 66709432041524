(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('CookieUtils', CookieUtils);

    CookieUtils.$inject = ['$window'];

    function CookieUtils ($window) {

        var service = {
            isCookiesEnabled: isCookiesEnabled
        };

        return service;

        function isCookiesEnabled() {
            var enabled = (navigator.cookieEnabled) ? true : false;

            if (typeof navigator.cookieEnabled == "undefined" && !enabled) {
                document.cookie="testcookie";
                enabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
            }
            return enabled;
        }

    }
})();
