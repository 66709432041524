(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .factory('UserManager', UserManager);

    UserManager.$inject = ['$resource'];

    function UserManager ($resource) {
        var service = $resource('api/location', {}, {
            'get': { method: 'GET' }
        });

        return service;
    }
})();
