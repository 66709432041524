(function() {
    'use strict';

    angular
        .module('userManagerApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', 'AlertService', 'LanguageNotificationChannel', '$scope'];

    function SettingsController (Principal, Auth, JhiLanguageService, AlertService, LanguageNotificationChannel, $scope) {
        var vm = this;

        vm.save = save;
        vm.originalAccount = null;
        vm.settingsAccount = null;
        vm.hasChanged = hasChanged;

        init();

        return vm;

        function init() {
            Principal.identity().then(function(account) {
                setAccount(account);
            });

            LanguageNotificationChannel.onLanguageChanged($scope, function () {
                JhiLanguageService.getCurrent().then(function(current) {
                    if (current != vm.originalAccount.langKey) {
                        vm.originalAccount.langKey = current;
                        vm.settingsAccount.langKey = current;
                    }
                });

            });
        }

        function setAccount(account) {
            vm.originalAccount = copyAccount(account);
            vm.settingsAccount = copyAccount(account);
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        function copyAccount(account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };

        function save () {
            JhiLanguageService.getCurrent().then(function(current) {
                if (vm.settingsAccount.langKey !== current) {
                    JhiLanguageService.changeLanguage(vm.settingsAccount.langKey);
                }
            });

            Auth.updateAccount(vm.settingsAccount).then(function() {
                AlertService.success('settings.messages.success');
                Principal.identity(true).then(function(account) {
                    setAccount(account);
                });
            }).catch(function() {
                AlertService.error('settings.messages.error');
            });
        }

        function hasChanged() {
            if(vm.originalAccount===null || vm.settingsAccount===null) return false;
            return JSON.stringify(vm.originalAccount) !== JSON.stringify(vm.settingsAccount);
        }
    }
})();
